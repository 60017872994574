import React, { useState } from "react";

import { Table, Form, Button } from "semantic-ui-react";

import { Select, ErrorsMessage } from "../UI";

import { validateTax, formatNumber } from "../../utils";

const initialState = {
  type: "",
  percentage: 0,
  base: 0,
};

const TaxForm = ({ addTax }) => {
  const [tax, setTax] = useState(initialState);
  const [errors, setErrors] = useState([]);

  const changeHandler = ({ target }) => {
    setTax((prevState) => ({
      ...prevState,
      [target.name]:
        target.type === "number" && target.value.length
          ? parseFloat(target.value)
          : target.value,
    }));
  };

  const share =
    !tax.percentage || !tax.base ? "" : tax.base * (tax.percentage / 100);

  return (
    <React.Fragment>
      <Table.Row>
        <Table.Cell>
          <Select
            fluid
            required
            name="type"
            placeholder="Tipo de impuesto"
            value={tax.type}
            options={[
              { key: "no", text: "-", value: "", disabled: true },
              { key: "IVA", text: "IVA", value: "01" },
            ]}
            onChange={changeHandler}
          />
        </Table.Cell>
        <Table.Cell>
          <Form.Input
            fluid
            type="number"
            min="0"
            placeholder="Tipo Impositivo"
            name="percentage"
            value={tax.percentage}
            onChange={changeHandler}
            icon="percent"
          />
        </Table.Cell>
        <Table.Cell>
          <Form.Input
            fluid
            type="number"
            min="0"
            placeholder="Base imponible"
            icon="euro"
            name="base"
            value={tax.base}
            onChange={changeHandler}
          />
        </Table.Cell>
        <Table.Cell>{formatNumber(share)}</Table.Cell>
        <Table.Cell>
          <Button
            aria-label="Añadir impuesto"
            basic
            onClick={() => {
              validateTax(tax)
                .then((tax) => {
                  addTax({ ...tax, share });
                  setTax(initialState);
                })
                .catch((err) =>
                  setErrors(err.inner.map(({ message }) => message))
                );
            }}
            color="green"
            icon="plus"
          />
        </Table.Cell>
      </Table.Row>
      {errors.length > 0 && (
        <Table.Row>
          <Table.Cell>
            <ErrorsMessage setErrors={setErrors} errors={errors} />
          </Table.Cell>
        </Table.Row>
      )}
    </React.Fragment>
  );
};

export default TaxForm;
