import React, { useState } from "react";
import { Form, Button } from "semantic-ui-react";

import TaxList from "../Tax/List";
import { Select, ErrorsMessage } from "../UI";

import { validateItem, formatNumber } from "../../utils";

const initialState = {
  description: "",
  quantity: 0,
  unitsMeasure: "01",
  unitPrice: 0,
};

const ItemForm = ({ addItem }) => {
  const [item, setItem] = useState(initialState);
  const [taxes, setTaxes] = useState([]);
  const [errors, setErrors] = useState([]);

  const changeHandler = ({ target }) => {
    setItem((prevState) => ({
      ...prevState,
      [target.name]:
        target.type === "number" && target.value.length
          ? parseFloat(target.value)
          : target.value,
    }));
  };

  const addTax = (taxLine) => setTaxes((prevTaxes) => [...prevTaxes, taxLine]);
  const removeTax = (index) => {
    const nextTaxesLines = [...taxes];
    nextTaxesLines.splice(index, 1);
    setTaxes(nextTaxesLines);
  };

  const totalPrice =
    !item.quantity || !item.unitPrice ? "" : item.unitPrice * item.quantity;

  return (
    <React.Fragment>
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            required
            name="description"
            value={item.description}
            onChange={changeHandler}
            label="Concepto"
            placeholder="Concepto"
          />
          <Form.Input
            required
            name="quantity"
            value={item.quantity}
            onChange={changeHandler}
            label="Cantidad"
            type="number"
            min="0"
            placeholder="Cantidad"
          />
          <Select
            required
            name="unitsMeasure"
            value={item.unitsMeasure}
            onChange={changeHandler}
            label="Medido en"
            placeholder="Medido en"
            options={[
              { key: "no", text: "-", value: "", disabled: true },
              { key: "U", text: "Unidades", value: "01" },
            ]}
          />
          <Form.Input
            required
            name="unitPrice"
            value={item.unitPrice}
            onChange={changeHandler}
            label="Precio Unitario"
            type="number"
            min="0"
            placeholder="Precio Unitario"
          />
          <Form.Input
            disabled
            value={formatNumber(totalPrice)}
            onChange={changeHandler}
            label="Importe total"
            type="text"
            min="0"
          />
        </Form.Group>
      </Form>
      <TaxList taxes={taxes} addTax={addTax} removeTax={removeTax} />
      {errors.length > 0 && (
        <ErrorsMessage setErrors={setErrors} errors={errors} />
      )}
      <Button
        aria-label="Añadir concepto"
        onClick={() => {
          validateItem(item)
            .then((item) => {
              addItem({ ...item, totalPrice: parseFloat(totalPrice), taxes });
            })
            .catch((err) => {
              setErrors(err.inner.map(({ message }) => message));
            });
        }}
        basic
        icon="save"
        content="Guardar"
        color="green"
      />
    </React.Fragment>
  );
};

export default ItemForm;
