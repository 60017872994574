import Dexie from "dexie";

const db = new Dexie("Face");

db.version(2).stores({
  actors:
    "++id,corporateName,identificator,personType,residenceTypeCode,taxIdentificationNumber,tradeName,address,postCode,town,province,countryCode,administrativeCenters",
});

export const saveActor = (actor) =>
  db.actors
    .add(actor)
    .catch((err) => console.log("problem saving record:", err));

export const getActors = () => db.actors.toArray();
