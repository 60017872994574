import React from "react";
import { Form, Tab } from "semantic-ui-react";

import { Select } from "../UI";

const AddressForm = ({ part, onChange }) => {
  const { address, postCode, town, province, countryCode } = part;
  return (
    <Tab.Pane aria-live="polite">
      <Form.Group widths="equal">
        <Form.Input
          onChange={onChange}
          fluid
          name="address"
          value={address}
          placeholder="Dirección"
        />
        <Form.Input
          onChange={onChange}
          fluid
          name="postCode"
          value={postCode}
          placeholder="Código postal"
        />
        <Form.Input
          onChange={onChange}
          fluid
          name="town"
          value={town}
          placeholder="Población"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          onChange={onChange}
          fluid
          name="province"
          value={province}
          placeholder="Provincia"
        />
        <Select
          onChange={onChange}
          fluid
          disabled
          name="countryCode"
          value={countryCode}
          placeholder="País"
          options={[
            { key: "no", text: "-", value: "", disabled: true },
            { key: "e", text: "España", value: "ESP" },
          ]}
        />
      </Form.Group>
    </Tab.Pane>
  );
};

export default AddressForm;
