import React from "react";
import { Form, Tab } from "semantic-ui-react";

const GeneralPartForm = ({ part, onChange }) => {
  const { taxIdentificationNumber, tradeName, corporateName } = part;
  return (
    <Tab.Pane aria-live="polite">
      <Form.Group widths="equal">
        <Form.Input
          required
          onChange={onChange}
          fluid
          name="taxIdentificationNumber"
          value={taxIdentificationNumber}
          placeholder="Identificador fiscal"
          pattern="([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])"
        />
        <Form.Input
          required
          fluid
          onChange={onChange}
          value={corporateName}
          name="corporateName"
          placeholder="Razón social"
        />
        <Form.Input
          onChange={onChange}
          fluid
          value={tradeName}
          name="tradeName"
          placeholder="Nombre comercial"
        />
      </Form.Group>
    </Tab.Pane>
  );
};

export default GeneralPartForm;
