import React from "react";
import { Form } from "semantic-ui-react";

export const Select = ({
  label,
  name,
  value,
  options,
  placeholder,
  onChange,
  ...rest
}) => {
  return (
    <Form.Select
      value={value}
      onChange={(e, { value }) =>
        onChange({
          target: { name, value },
        })
      }
      fluid
      label={label}
      options={options}
      placeholder={placeholder}
      {...rest}
    />
  );
};
