import React from "react";

import BasicScreen from "./BasicScreen";
import TicketForm from "../components/TicketForm";
const Face = () => (
  <BasicScreen>
    <TicketForm />
  </BasicScreen>
);

export default Face;
