import React from "react";
import { Container, Segment, Header, List, Image } from "semantic-ui-react";

import useScreenSize from "@bit/rigobauer.iteisa-components.use-screen-size";

import BasicScreen from "./BasicScreen";
import ActorIntrinsicImage from "../images/actorIntrinsic.png";
import ActorIntrinsicImageSmall from "../images/actorIntrinsicImageSmall.png";
import ActorGeneralInfoImage from "../images/actorGeneralInfo.png";
import ActorGeneralInfoSmallImage from "../images/actorGeneralInfoSmallImage.png";
import ActorAddressInfoImage from "../images/actorAddress.png";
import ActorAddressInfoSmallImage from "../images/actorAddressSmall.png";
import FaceImage from "../images/Face.png";
import FaceSmallImage from "../images/FaceSmall.png";
import ModalExample from "../images/modalExample.png";
import ModalExampleSmall from "../images/modalExampleSmall.png";
import ModalTax from "../images/modalTax.png";
import ModalTaxSmall from "../images/modalTaxSmall.png";
import ActorsPreload from "../images/ActorsPreload.png";
import ActorsPreloadSmall from "../images/ActorsPreloadSmall.png";

const screenSizes = {
  "2xl": 1536,
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 0,
};

const imagesSet = {
  ActorIntrinsicImage: {
    sm: ActorIntrinsicImageSmall,
    default: ActorIntrinsicImage,
  },
  ActorGeneralInfoImage: {
    sm: ActorGeneralInfoSmallImage,
    default: ActorGeneralInfoImage,
  },
  ActorAddressInfoImage: {
    sm: ActorAddressInfoSmallImage,
    default: ActorAddressInfoImage,
  },
  FaceImage: {
    sm: FaceSmallImage,
    default: FaceImage,
  },
  ModalExample: {
    sm: ModalExampleSmall,
    default: ModalExample,
  },
  ModalTax: {
    sm: ModalTaxSmall,
    default: ModalTax,
  },
  ActorsPreload: {
    sm: ActorsPreloadSmall,
    default: ActorsPreload,
  },
};

const Manual = () => {
  const [screenWidth] = useScreenSize(screenSizes);

  const getImageBySize = (entity) => {
    return imagesSet[entity] && imagesSet[entity][screenWidth]
      ? imagesSet[entity][screenWidth]
      : imagesSet[entity].default;
  };

  return (
    <BasicScreen>
      <Container style={{ minHeight: "80vh", paddingTop: "4rem" }}>
        <h1 aria-live="polite">Manual de uso</h1>
        <Segment role="main" aria-live="polite">
          <Header as="h2">Aviso</Header>
          <p>
            <a target="_blank" rel="noreferrer" href="https://www.zitelia.com/">
              ZITELIA
            </a>{" "}
            no recaba información alguna de este formulario, todos los datos que
            aparezcan en él están almacenados en el navegador del ordenador que
            estés utilizando a través de{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API"
            >
              IndexedDB
            </a>
            . Si estás utilizando un ordenador público y quieres asegurarte de
            eliminar los datos sigue estos pasos:
          </p>
          <List bulleted>
            <List.Item>
              Abre la consola del navegador presionando clic derecho y
              seleccionando la opción "Inspeccionar"
            </List.Item>
            <List.Item>Luego, debes ir a la pestaña "Aplicación"</List.Item>
            <List.Item>
              Seguidamente, debes ir a la sección "Storage" en el lateral
              izquierdo y desplegar la opción "IndexedDB"
            </List.Item>
            <List.Item>
              Ahora, debes seleccionar la opción "FACe ~
              https://face.zitelia.com/"
            </List.Item>
            <List.Item>
              Por último, debes pinchar sobre el botón "Elminar base de datos"
            </List.Item>
          </List>
          <p>
            Este servicio es ofrecido de manera gratuita por{" "}
            <a target="_blank" rel="noreferrer" href="https://www.zitelia.com/">
              ZITELIA
            </a>
            , si tienes cualquier problema o sugerencia puedes hacérnosla saber
            a través de{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.zitelia.com/contacta/"
            >
              nuestras formas de contacto
            </a>
            .
          </p>
          <p>
            Si deseas ampliar las funcionalidades de esta aplicación,
            personalizarla, sincronizarla con tu programa de gestión o hacer un
            desarrollo a medida no dudes en ponerte en{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.zitelia.com/contacta/"
            >
              contacto
            </a>{" "}
            con nosotros.
          </p>
        </Segment>
        <Segment role="main" aria-live="polite" vertical>
          <Header as="h2">Campos generales</Header>
          <p>Deberás rellenar:</p>
          <List ordered>
            <List.Item>Código de factura.</List.Item>
            <List.Item>Número de factura.</List.Item>
            <List.Item>Fecha de expedición.</List.Item>
          </List>
        </Segment>
        <Segment aria-live="polite" vertical>
          <Header as="h2">Actores</Header>
          <p>Rellenar los campos del emisor y receptor de la factura.</p>
          <List ordered>
            <List.Item>
              Seleccionar el tipo de residencia y el tipo de persona, según sea
              el caso.
              <div className="image-container">
                <Image
                  alt="Formulario del tipo de persona del actor"
                  size="huge"
                  src={getImageBySize("ActorIntrinsicImage")}
                />
              </div>
            </List.Item>
            <List.Item>
              Seguidamente, en la pestaña <strong>general</strong>, deberás
              introducir la información fiscal del emisor y receptor:
              <List>
                <List.Item>Identificación fiscal.</List.Item>
                <List.Item>Razón Social.</List.Item>
                <List.Item>Nombre comercial.</List.Item>
              </List>
              <div className="image-container">
                <Image
                  alt="Formulario de información fiscal del actor"
                  size="huge"
                  src={getImageBySize("ActorGeneralInfoImage")}
                />
              </div>
            </List.Item>
            <List.Item>
              Después, debes pinchar sobre la pestaña
              <strong> Dirección </strong> y rellenar la información.
              <div className="image-container">
                <Image
                  alt="Formulario de la dirección del actor"
                  size="huge"
                  src={getImageBySize("ActorAddressInfoImage")}
                />
              </div>
            </List.Item>
            <List.Item>
              Finalmente habrá que rellenar las casillas correspondientes a la
              administración pulsando sobre cada una de ellas. El navegador
              guardará la información para la próxima vez que entres.
              <div className="image-container">
                <Image
                  alt="Formulario face, centros adminitstrativos"
                  size="huge"
                  src={getImageBySize("FaceImage")}
                />
              </div>
              Si ya está cumplimentada la pestaña de dirección únicamente será
              necesario rellenar los campos "Código" y "Nombre".
            </List.Item>
          </List>
        </Segment>
        <Segment aria-live="polite" vertical>
          <Header as="h2">Conceptos</Header>
          <p>Seguidamente deben añadirse los conceptos de la factura.</p>
          <List ordered>
            <List.Item>
              Debes pulsar en el botón '+' situado a la derecha y rellenar los
              datos correpondientes a un concepto.
              <div className="image-container">
                <Image
                  alt="Ejmplo del modal para añadir conceptos"
                  size="big"
                  src={getImageBySize("ModalExample")}
                />
              </div>
            </List.Item>
            <List.Item>
              A continuación añadimos el impuesto que debe repercutirse en dicho
              concepto.
              <List>
                <List.Item>
                  Selecciona el tipo de impuesto del desplegable.
                </List.Item>
                <List.Item>
                  Introduce un porcentaje al que equivale el impuesto, por
                  ejemplo, para el IVA estándar sería 21, mientras que para el
                  IVA reducido sería 10.
                </List.Item>
                <List.Item>
                  Añadir el precio base del concepto sobre el que se calculará
                  el impuesto y pulsa el botón "+" para que el impuesto sea
                  computado.
                </List.Item>
              </List>
              <div className="image-container">
                <Image
                  alt="Ejemplo añadir impuesto"
                  size="big"
                  src={getImageBySize("ModalTax")}
                />
              </div>
            </List.Item>
            <List.Item>Pulsa el botón Guardar</List.Item>
            <List.Item>
              Finalmente, pulsa en el botón "Generar Factura".
            </List.Item>
          </List>
        </Segment>
        <Segment aria-live="polite" vertical>
          <Header as="h2">¡Importante!</Header>
          <p>
            Debido a que habitualmente las facturas son generadas para los
            mismos remitentes, en{" "}
            <a target="_blank" rel="noreferrer" href="https://www.zitelia.com/">
              ZITELIA
            </a>{" "}
            hemos preparado una forma de recordar los datos del mismo a través
            del uso de{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API"
            >
              IndexedDB.
            </a>{" "}
            De esta forma facilitaremos la creación de nuevas facturas en las
            siguientes ocasiones.
            <br />
            <br />
            No te preocupes, esta información solo estará disponible en ese
            equipo y navegador.
            <br />
            <br />
            Para ver la información almacenada únicamente hay que desplegar la
            opción 'Actor' del formulario.
          </p>
          <div className="image-container">
            <Image
              alt="Ejemplo pre cargado de actores "
              size="big"
              src={getImageBySize("ActorsPreload")}
            />
          </div>
        </Segment>
      </Container>
    </BasicScreen>
  );
};

export default Manual;
