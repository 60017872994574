import React from "react";
import { Form } from "semantic-ui-react";
import DatePicker, { registerLocale } from "react-datepicker";
import { es } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("es", es);

export const DateInput = ({ name, value, label, onChange, ...rest }) => {
  const CustomDatePicker = () => (
    <DatePicker
      locale="es"
      dateFormat="dd-MM-yyyy"
      selected={value}
      onChange={(date) => onChange({ target: { name, value: date } })}
    />
  );
  return (
    <Form.Field width={16} label={label} control={CustomDatePicker} {...rest} />
  );
};
