// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Manual from "./Screens/Manual";
import Face from "./Screens/Face";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/manual">
            <Manual />
          </Route>
          <Route path="/">
            <Face />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
