import formatIso from "date-fns/formatISO";

export const parseTicket = (ticket, items, seller, buyer) => {
  const grossTotal = items.reduce((acc, { totalPrice }) => acc + totalPrice, 0);
  const taxesTotal = items.reduce(
    (acc, { taxes }) =>
      acc + taxes.reduce((taxAcc, { share }) => taxAcc + share, 0),
    0
  );
  const totalInvoicesAmoun = grossTotal + taxesTotal;
  return {
    "fe:Facturae": {
      "@xmlns:ds": "http://www.w3.org/2000/09/xmldsig#",
      "@xmlns:fe": "http://www.facturae.es/Facturae/2014/v3.2.1/Facturae",
      FileHeader: {
        SchemaVersion: "3.2.1",
        Modality: "I",
        InvoiceIssuerType: "EM",
        Batch: {
          BatchIdentifier: "P3904000A09681C",
          InvoicesCount: "1",
          TotalInvoicesAmount: { TotalAmount: totalInvoicesAmoun.toFixed(2) },
          TotalOutstandingAmount: {
            TotalAmount: totalInvoicesAmoun.toFixed(2),
          },
          TotalExecutableAmount: { TotalAmount: totalInvoicesAmoun.toFixed(2) },
          InvoiceCurrencyCode: "EUR",
        },
      },
      Parties: {
        SellerParty: parsePart(seller),
        BuyerParty: parsePart(buyer, true),
      },
      Invoices: parseInvoice(ticket, items),
    },
  };
};

const parsePart = (
  {
    personType: PersonTypeCode,
    residenceTypeCode: ResidenceTypeCode,
    taxIdentificationNumber: TaxIdentificationNumber,
    corporateName: CorporateName,
    tradeName: TradeName,
    ...part
  },
  includeAdministrativeCentre = false
) => {
  const {
    address: Address,
    postCode: PostCode,
    town: Town,
    province: Province,
    countryCode: CountryCode,
  } = part;
  return {
    TaxIdentification: {
      PersonTypeCode,
      ResidenceTypeCode,
      TaxIdentificationNumber,
    },
    ...(includeAdministrativeCentre
      ? {
          AdministrativeCentres: parseAdministrativeCenter(part),
        }
      : {}),
    LegalEntity: {
      CorporateName,
      ...(!includeAdministrativeCentre ? { TradeName } : {}),
      AddressInSpain: {
        Address,
        PostCode,
        Town,
        Province,
        CountryCode,
      },
    },
  };
};

const parseAdministrativeCenter = ({
  administrativeCenters,
  address: defaultAddres,
  postCode: defaultPostCode,
  town: defaultTown,
  province: defaultProvince,
  countryCode: defaultCountryCode,
}) => {
  const parsedCenters = administrativeCenters.map(
    ({
      centreCode: CentreCode,
      name: Name,
      roleTypeCode: RoleTypeCode,
      address: Address,
      postCode: PostCode,
      town: Town,
      privince: Province,
      countryCode: CountryCode,
    }) => ({
      CentreCode,
      RoleTypeCode,
      Name,
      AddressInSpain: {
        Address: Address || defaultAddres,
        PostCode: PostCode || defaultPostCode,
        Town: Town || defaultTown,
        Province: Province || defaultProvince,
        CountryCode: CountryCode || defaultCountryCode,
      },
    })
  );
  return { AdministrativeCentre: parsedCenters };
};

const parseInvoice = (
  { number: InvoiceNumber, seriesCode: InvoiceSeriesCode, date },
  items
) => {
  return {
    Invoice: {
      InvoiceHeader: {
        InvoiceNumber,
        InvoiceSeriesCode,
        InvoiceDocumentType: "FC",
        InvoiceClass: "OO",
      },
      InvoiceIssueData: {
        IssueDate: formatIso(new Date(date)).split("T")[0],
        InvoiceCurrencyCode: "EUR",
        TaxCurrencyCode: "EUR",
        LanguageName: "es",
      },
      TaxesOutputs: parseTaxes(extractAllTaxesFromItems(items)),
      InvoiceTotals: parseTotals(items),
      Items: parseItems(items),
    },
  };
};

const extractAllTaxesFromItems = (items) =>
  items.map(({ taxes }) => taxes).flat();

const parseTaxes = (taxes) => {
  const parsedTaxes = taxes.map(
    ({ type: TaxTypeCode, percentage, base, share }) => ({
      TaxTypeCode,
      TaxRate: percentage.toFixed(2),
      TaxableBase: {
        TotalAmount: base.toFixed(2),
      },
      TaxAmount: {
        TotalAmount: share.toFixed(2),
      },
    })
  );
  return { Tax: parsedTaxes };
};

const parseTotals = (items) => {
  let TotalGrossAmount = 0;
  let TotalGeneralDiscounts = 0;
  let TotalGeneralSurcharges = 0;
  let TotalGrossAmountBeforeTaxes = 0;
  let TotalTaxOutputs = 0;
  let TotalTaxesWithheld = 0;
  let InvoiceTotal = 0;

  items.forEach(({ totalPrice, taxes }) => {
    TotalGrossAmount += totalPrice;
    TotalGrossAmountBeforeTaxes += TotalGrossAmountBeforeTaxes;
    TotalTaxOutputs += taxes.reduce((acc, { share }) => acc + share, 0);
  });

  InvoiceTotal = (TotalGrossAmount + TotalTaxOutputs).toFixed(2);
  return {
    TotalGrossAmount: TotalGrossAmount.toFixed(2),
    TotalGeneralDiscounts: TotalGeneralDiscounts.toFixed(2),
    TotalGeneralSurcharges: TotalGeneralSurcharges.toFixed(2),
    TotalGrossAmountBeforeTaxes: TotalGrossAmount.toFixed(2),
    TotalTaxOutputs: TotalTaxOutputs.toFixed(2),
    TotalTaxesWithheld: TotalTaxesWithheld.toFixed(2),
    InvoiceTotal,
    TotalOutstandingAmount: InvoiceTotal,
    TotalExecutableAmount: InvoiceTotal,
  };
};

const parseItems = (items) => {
  const parsedItems = items.map(
    ({
      description: ItemDescription,
      quantity: Quantity,
      totalPrice,
      unitsMeasure: UnitOfMeasure,
      taxes,
    }) => ({
      ItemDescription,
      Quantity,
      UnitOfMeasure,
      UnitPriceWithoutTax: totalPrice.toFixed(2),
      TotalCost: totalPrice.toFixed(2),
      GrossAmount: totalPrice.toFixed(2),
      TaxesOutputs: parseTaxes(taxes),
    })
  );
  return { InvoiceLine: parsedItems };
};
