import React, { useState } from "react";
import { Form, Tab, Accordion } from "semantic-ui-react";

import { Select } from "../UI";

const FaceForm = ({ part, onChange }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const { administrativeCenters } = part;
  const forms = administrativeCenters.map((adminCenter, index) => {
    return (
      <Accordion key={adminCenter.roleTypeCode} fluid styled aria-live="polite">
        <Accordion.Title
          active={activeIndex === index}
          index={index}
          onClick={() => {
            const nextValue = activeIndex === index ? -1 : index;
            setActiveIndex(nextValue);
          }}
        >
          {adminCenter.title}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === index}>
          <Form.Group widths="equal">
            <Form.Input
              onChange={({ target }) => onChange(index, target)}
              fluid
              name="centreCode"
              placeholder="Código"
              value={adminCenter.centreCode}
            />
            <Form.Input
              onChange={({ target }) => onChange(index, target)}
              fluid
              name="name"
              placeholder="Nombre"
              value={adminCenter.name}
            />
            <Form.Input
              onChange={({ target }) => onChange(index, target)}
              fluid
              name="address"
              value={adminCenter.address}
              placeholder="Dirección"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              onChange={({ target }) => onChange(index, target)}
              fluid
              name="postCode"
              value={adminCenter.postCode}
              placeholder="Código postal"
            />
            <Form.Input
              onChange={({ target }) => onChange(index, target)}
              fluid
              name="town"
              value={adminCenter.town}
              placeholder="Población"
            />
            <Form.Input
              onChange={({ target }) => onChange(index, target)}
              fluid
              name="privince"
              value={adminCenter.privince}
              placeholder="Provincia"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Select
              name="countryCode"
              value={adminCenter.countryCode}
              disabled
              options={[
                { key: "no", text: "-", value: "", disabled: true },
                { key: "e", text: "España", value: "ESP" },
              ]}
              placeholder="País"
              onChange={({ target }) => onChange(index, target)}
            />
          </Form.Group>
        </Accordion.Content>
      </Accordion>
    );
  });

  return <Tab.Pane>{forms}</Tab.Pane>;
};

export default FaceForm;
