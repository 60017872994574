import React, { Fragment } from "react";

import { Header, Footer } from "../components/UI";
const BasicScreen = (props) => {
  return (
    <Fragment>
      <Header />
      {props.children}
      <Footer />
    </Fragment>
  );
};

export default BasicScreen;
