import React from "react";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

export const MainMenu = ({ vertical = false, ...rest }) => {
  const pathname = window.location.pathname;

  return (
    <Menu aria-live="polite" secondary vertical={vertical} {...rest}>
      <Menu.Item active={pathname === "/"}>
        <Link to="/">Inicio</Link>
      </Menu.Item>
      <Menu.Item active={pathname === "/manual"}>
        <Link to="/manual">Manual de uso</Link>
      </Menu.Item>
      <Menu.Item
        href="https://www.zitelia.com/nuestros-trabajos/"
        target="_blank"
        name="about"
        content="¿Qué hacemos?"
      />
      <Menu.Item
        href="https://www.zitelia.com/contacta/"
        target="_blank"
        name="Contacto"
      />
    </Menu>
  );
};
