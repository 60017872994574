export const getPartInitialState = (type) => ({
  type,
  corporateName: "",
  identificator: "",
  personType: "",
  residenceTypeCode: "",
  taxIdentificationNumber: "",
  tradeName: "",
  address: "",
  postCode: "",
  town: "",
  province: "",
  countryCode: "ESP",
  administrativeCenters: [
    {
      roleTypeCode: "01",
      centreCode: "",
      title: "Oficina contable",
      name: "",
      address: "",
      postCode: "",
      town: "",
      privince: "",
      countryCode: "ESP",
    },
    {
      roleTypeCode: "02",
      centreCode: "",
      title: "Órgano gestor",
      name: "",
      address: "",
      postCode: "",
      town: "",
      privince: "",
      countryCode: "ESP",
    },
    {
      roleTypeCode: "03",
      centreCode: "",
      title: "Unidad tramitadora",
      name: "",
      address: "",
      postCode: "",
      town: "",
      privince: "",
      countryCode: "ESP",
    },
  ],
});
