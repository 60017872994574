import React from "react";

import { Header, Table } from "semantic-ui-react";
import { formatNumber } from "./../utils";

const Summary = ({ items }) => {
  const grossTotal = items.reduce((acc, { totalPrice }) => acc + totalPrice, 0);
  const taxesTotal = items.reduce(
    (acc, { taxes }) =>
      acc + taxes.reduce((taxAcc, { share }) => taxAcc + share, 0),
    0
  );
  const ticketTotal = grossTotal + taxesTotal;

  return (
    <React.Fragment>
      <Header as="h3">Totales</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Concepto</Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Importe bruto</Table.Cell>
            <Table.Cell>{formatNumber(grossTotal)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell> + Impuestos repercutidos</Table.Cell>
            <Table.Cell>{formatNumber(taxesTotal)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>{formatNumber(ticketTotal)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default Summary;
