import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Aller/Aller_Bd.ttf";
import "./fonts/Aller/Aller_BdIt.ttf";
import "./fonts/Aller/Aller_It.ttf";
import "./fonts/Aller/Aller_Lt.ttf";
import "./fonts/Aller/Aller_LtIt.ttf";
import "./fonts/Aller/Aller_Rg.ttf";
import "./fonts/Aller/AllerDisplay.ttf";
import "semantic-ui-css/semantic.min.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
