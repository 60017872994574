import React, { useState } from "react";
import useScreenSize from "@bit/rigobauer.iteisa-components.use-screen-size";
import { Header, Table, Button, Modal } from "semantic-ui-react";

import Form from "./Form";
import { formatNumber } from "../../utils";

const Item = ({ index, item, removeItem, isSmallScreen }) => {
  const taxesLabel = item.taxes.map(
    ({ type, percentage }) => `${percentage} ~ ${type}`
  );
  const taxesTotal = item.taxes.reduce((acc, { share }) => acc + share, 0);
  const itemTotal = taxesTotal + item.totalPrice;

  if (isSmallScreen)
    return (
      <Table.Row key={index}>
        <Table.Cell>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="full-width">
              <Header>{`${index + 1}. ${item.description}`}</Header>
            </div>
            <div className="half-width margin-1">
              <strong>Cantidad: </strong>
              <label className="normal-weight-label">{item.quantity}</label>
            </div>
            <div className="half-width margin-1">
              <strong>Unidad de medida: </strong>
              <label className="normal-weight-label">{item.unitsMeasure}</label>
            </div>
            <div className="half-width margin-1">
              <strong>Precio: </strong>
              <label className="normal-weight-label">{item.totalPrice} €</label>
            </div>
            <div className="half-width margin-1">
              <strong>Total de impuestos: </strong>
              <label className="normal-weight-label">
                {formatNumber(taxesTotal)}
              </label>
            </div>
            <div className="half-width margin-1">
              <strong>Total: </strong>
              <label className="normal-weight-label">
                {formatNumber(itemTotal)}
              </label>
            </div>
            <div className="half-width margin-1">
              <Button
                aria-label="Eiminar concepto"
                floated="right"
                onClick={() => removeItem(index)}
                color="red"
                style={{ backgroundColor: "#d83256" }}
                icon="trash"
              />
            </div>
          </div>
        </Table.Cell>
      </Table.Row>
    );

  return (
    <Table.Row key={index}>
      <Table.Cell>{index + 1}</Table.Cell>
      <Table.Cell>{item.description}</Table.Cell>
      <Table.Cell>{item.quantity}</Table.Cell>
      <Table.Cell>{item.unitsMeasure}</Table.Cell>
      <Table.Cell>{taxesLabel}</Table.Cell>
      <Table.Cell>{formatNumber(item.totalPrice)}</Table.Cell>
      <Table.Cell>{formatNumber(taxesTotal)}</Table.Cell>
      <Table.Cell>{formatNumber(itemTotal)}</Table.Cell>
      <Table.Cell>
        <Button
          aria-label="Eliminar concepto"
          onClick={() => removeItem(index)}
          style={{ backgroundColor: "#d83256" }}
          color="red"
          icon="trash"
        />
      </Table.Cell>
    </Table.Row>
  );
};

const Items = ({ items, setItems }) => {
  const [open, setOpen] = useState(false);
  const [screenWidthSize] = useScreenSize();
  const isSmallScreen = ["sm"].includes(screenWidthSize);

  const removeItem = (index) => {
    setItems((prevItems) => {
      const nextItems = [...prevItems];
      nextItems.splice(index, 1);
      return nextItems;
    });
  };

  return (
    <React.Fragment>
      <Header as="h2" aria-live="polite">
        Conceptos
      </Header>
      <Table>
        <Table.Header>
          <Table.Row>
            {!isSmallScreen && (
              <React.Fragment>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Descripción</Table.HeaderCell>
                <Table.HeaderCell>Cantidad</Table.HeaderCell>
                <Table.HeaderCell>U. de medida</Table.HeaderCell>
                <Table.HeaderCell>Impuestos repercutidos</Table.HeaderCell>
                <Table.HeaderCell>Importe</Table.HeaderCell>
                <Table.HeaderCell>Total de impuestos</Table.HeaderCell>
                <Table.HeaderCell>Importe total</Table.HeaderCell>
              </React.Fragment>
            )}
            <Table.HeaderCell>
              <Modal
                size="fullscreen"
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={
                  <Button
                    aria-label="Añadir concepto"
                    icon="plus"
                    style={{ backgroundColor: "#34c671" }}
                    onClick={() => setOpen(true)}
                    positive
                  />
                }
              >
                <Modal.Content>
                  <Form
                    addItem={(item) => {
                      setItems((prevItems) => [...prevItems, item]);
                      setOpen(false);
                    }}
                  />
                </Modal.Content>
              </Modal>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item, id) => (
            <Item
              key={"item_" + id}
              item={item}
              index={id}
              removeItem={removeItem}
              isSmallScreen={isSmallScreen}
            />
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default Items;
