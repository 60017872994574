import * as yup from "yup";

const getPartValidationSchema = (partName = "Emisor.") => ({
  corporateName: yup.string(),
  personType: yup
    .string()
    .required(`El tipo de persona del ${partName} es requerido.`),
  residenceTypeCode: yup
    .string()
    .required(`El tipo de residencia del ${partName} es requerido.`),
  taxIdentificationNumber: yup
    .string()
    .required(`El identificador fiscal del ${partName} es requerido.`),
  tradeName: yup.string(),
  address: yup.string().required(`La dirección del ${partName} es requerida.`),
  postCode: yup
    .string()
    .required(`El código postal del ${partName} es requerido.`),
  town: yup.string().required(`La ciudad del ${partName} es requerido.`),
  province: yup.string().required(`La provincia del ${partName} es requerida.`),
});

const getValidationSchema = () => {
  return yup.object().shape({
    ticket: yup.object().shape({
      seriesCode: yup
        .string()
        .required("El identificador de la factura es requerido."),
      number: yup.string().required("El número de la factura es requerido."),
      date: yup.string().required("La fecha de expedición es requerida."),
    }),
    seller: yup.object().shape(getPartValidationSchema("Vendedor")),
    buyer: yup.object().shape({
      ...getPartValidationSchema("Comprador"),
    }),
    items: yup
      .array()
      .min(1, "Es necesario, al menos, un item.")
      .of(yup.object().shape(getItemValidationSchema())),
  });
};

const getItemValidationSchema = () => ({
  description: yup
    .string("El concepto del ítem es requerido")
    .required("El concepto del ítem es requerido"),
  quantity: yup
    .number("La cantidad debe ser un número entero")
    .required("La cantidad es requerida")
    .positive("La cantidad debe ser positiva")
    .integer("La cantidad ser un entero"),
  unitsMeasure: yup.string().required("Las unidades son requeridas."),
  unitPrice: yup
    .number()
    .required()
    .positive("El precio unitario debe ser un número y positivo"),
  taxes: yup
    .array()
    .min(1, "Es necesario, al menos, un impuesto.")
    .of(
      yup.object().shape({
        type: yup.string().required("El tipo del impuesto es requerido."),
        percentage: yup.number().required().positive(),
        base: yup.number().required().positive(),
      })
    ),
});

const getTaxValidationSchema = () => ({
  type: yup.string().required("El tipo del impuesto es requerido."),
  percentage: yup
    .number()
    .required()
    .positive("El tipo impositivo debe ser un número  y positivo."),
  base: yup
    .number()
    .required()
    .positive("La base imponible debe ser un número  y positivo."),
});

export const validateTax = (tax) => {
  return yup
    .object()
    .shape(getTaxValidationSchema())
    .validate(tax, { abortEarly: false });
};

export const validateItem = (item) => {
  return yup
    .object()
    .shape(getItemValidationSchema())
    .validate(item, { abortEarly: false });
};

export const validateTicket = (ticket, items, buyer, seller) => {
  return getValidationSchema().validate(
    { ticket, items, buyer, seller },
    { abortEarly: false }
  );
};
