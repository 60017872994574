import React from "react";
import { Menu, Container } from "semantic-ui-react";

export const Footer = () => {
  return (
    <div aria-live="polite" className="zitelia-footer">
      <Menu aria-live="polite" secondary>
        <Menu.Item
          href="https://face.gob.es/es/facturas/acceder-factura"
          className="footer-link"
          target="_blank"
          name="register"
          content="Remitir factura"
        />
        <Menu.Item
          href="https://firmaelectronica.gob.es/Home/Descargas.html"
          className="footer-link"
          target="_blank"
          name="autosign"
          content="Autofirma"
        />
      </Menu>
      <Container textAlign="center">
        <p>
          Desarrollo de software a medida realizado por  &nbsp;
          <a href="https://www.zitelia.com/" className="footer-link">
             Zitelia Soluciones Tecnológicas
          </a>
        </p>
      </Container>
    </div>
  );
};
