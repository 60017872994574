import React from "react";
import { Table, Button } from "semantic-ui-react";
import useScreenSize from "@bit/rigobauer.iteisa-components.use-screen-size";

import Form from "./Form";
import { formatNumber } from "../../utils";

const List = ({ taxes, addTax, removeTax }) => {
  const [screenWidthSize] = useScreenSize();
  const isSmallScreen = ["sm"].includes(screenWidthSize);

  const Item = ({ index, type, percentage, base, share }) => {
    if (isSmallScreen)
      return (
        <Table.Row key={index}>
          <Table.Cell>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="half-width margin-1">
                <strong>Tipo: </strong>
                <label className="normal-weight-label">{type}</label>
              </div>
              <div className="half-width margin-1">
                <strong>Porcentaje: </strong>
                <label className="normal-weight-label">{percentage} %</label>
              </div>
              <div className="half-width margin-1">
                <strong>Base: </strong>
                <label className="normal-weight-label">
                  {formatNumber(base)}
                </label>
              </div>
              <div className="half-width margin-1">
                <strong>Total repercutido: </strong>
                <label className="normal-weight-label">
                  {formatNumber(share)}
                </label>
              </div>
              <div className="half-width margin-1">
                <Button
                  aria-label="Eliminar impuesto"
                  basic
                  onClick={() => removeTax(index)}
                  style={{ backgroundColor: "#d83256" }}
                  color="red"
                  icon="trash"
                />
              </div>
            </div>
          </Table.Cell>
        </Table.Row>
      );

    return (
      <Table.Row key={index}>
        <Table.Cell>{type}</Table.Cell>
        <Table.Cell>{percentage}</Table.Cell>
        <Table.Cell>{formatNumber(base)}</Table.Cell>
        <Table.Cell>{formatNumber(share)}</Table.Cell>
        <Table.Cell>
          <Button
            aria-label="Eliminar impuesto"
            basic
            onClick={() => removeTax(index)}
            style={{ backgroundColor: "#d83256" }}
            color="red"
            icon="trash"
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Table columns={5}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Impuesto</Table.HeaderCell>
          {!isSmallScreen && (
            <React.Fragment>
              <Table.HeaderCell>Tipo Impositivo %</Table.HeaderCell>
              <Table.HeaderCell>Base imponible</Table.HeaderCell>
              <Table.HeaderCell>Total repercutido</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </React.Fragment>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Form addTax={addTax} />
        {taxes.map((tax, index) => (
          <Item key={index} index={index} {...tax} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default List;
