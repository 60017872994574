import React, { useEffect, useState } from "react";
import { Header, Form, Tab } from "semantic-ui-react";

import FaceForm from "./Face";
import AddressForm from "./Address";
import GeneralPartForm from "./General";

import { Select, Radio } from "../UI";
import { getActors } from "../../utils";

const PartForm = ({ part, type, onChange }) => {
  const [availableActors, setAvailableActors] = useState([]);
  useEffect(() => {
    getActors().then((actors) => setAvailableActors(actors));
  }, [type]);

  const { personType, residenceTypeCode } = part;
  const changeHandler = ({ target }) => {
    onChange((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const administrativeCentersChangeHandler = (index, { name, value }) => {
    onChange((prevState) => {
      const administrativeCenters = [...prevState.administrativeCenters];
      administrativeCenters[index][name] = value;
      return {
        ...prevState,
        administrativeCenters,
      };
    });
  };

  const panes = [
    {
      menuItem: "General",
      render: () => <GeneralPartForm part={part} onChange={changeHandler} />,
    },
    {
      menuItem: "Dirección",
      render: () => <AddressForm part={part} onChange={changeHandler} />,
    },
    {
      menuItem: "FACEs",
      render: () => (
        <FaceForm part={part} onChange={administrativeCentersChangeHandler} />
      ),
    },
  ];

  const availableActorsOptions = availableActors.map(
    ({ corporateName }, index) => ({
      key: index,
      text: corporateName,
      value: index,
    })
  );

  return (
    <React.Fragment>
      <div style={{ marginTop: "1rem" }} aria-live="polite">
        <Header as='h2'>{type}</Header>
        <Form.Group widths="equal">
          {availableActorsOptions.length > 0 && (
            <Select
              name="selectedActor"
              options={availableActorsOptions}
              placeholder="Actor"
              onChange={({ target }) => {
                onChange(availableActors[target.value]);
              }}
            />
          )}
          <Select
            name="residenceTypeCode"
            value={residenceTypeCode}
            options={[
              { key: "no", text: "-", value: "", disabled: true },
              { key: "e", text: "Extranjero", value: "E" },
              { key: "r", text: "Residente", value: "R" },
              { key: "re", text: "Residente de la UE", value: "RE" },
            ]}
            placeholder="Tipo de residencia"
            onChange={changeHandler}
            aria-label="Tipo de residencia del actor"
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Radio
            onChange={changeHandler}
            aria-label="Tipo de persona jurídica"
            name="personType"
            namePrefix={type}
            label="Persona jurídica"
            value="J"
            checked={personType === "J"}
          />
          <Radio
            onChange={changeHandler}
            aria-label="Tipo de persona individual"
            name="personType"
            namePrefix={type}
            label="Persona individual"
            value="I"
            checked={personType === "I"}
          />
        </Form.Group>
        <Tab panes={panes} />
      </div>
    </React.Fragment>
  );
};

export default PartForm;
