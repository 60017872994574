import React from "react";
import { Form } from "semantic-ui-react";

export const Radio = ({
  label,
  name,
  namePrefix,
  value,
  onChange,
  checked,
  ...rest
}) => {
  return (
    <Form.Radio
      onChange={(e, { value }) => {
        onChange({
          target: { name, value },
        });
      }}
      name={namePrefix + name}
      label={label}
      value={value}
      checked={checked}
      {...rest}
    />
  );
};
