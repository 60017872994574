import React, { useState, useRef } from "react";

import { Container, Button, Form, Grid } from "semantic-ui-react";
import useScreenSize from "@bit/rigobauer.iteisa-components.use-screen-size";

import PartForm from "./Part/Form";
import Items from "./Item/List";
import Summary from "./Summary";

import { getPartInitialState } from "./Part/constants";

import { parseTicket, generateXml, validateTicket, saveActor } from "../utils";

import { DateInput, ErrorsMessage } from "./UI";

const initialTicketState = {
  seriesCode: "",
  number: "",
  date: "",
};

const GeneralForm = ({ onChange, seriesCode, number, date }) => {
  return (
    <React.Fragment>
      <Grid.Column>
        <Form.Group role="main">
          <Form.Input
            label="Código de factura"
            width={7}
            name="seriesCode"
            value={seriesCode}
            onChange={onChange}
            fluid
            placeholder="C"
            required
          />
          <Form.Input
            label="Número de factura"
            width={9}
            fluid
            name="number"
            value={number}
            onChange={onChange}
            placeholder="10589"
            required
          />
        </Form.Group>
      </Grid.Column>
      <Grid.Column aria-live="polite">
        <Form.Group widths="equal">
          <DateInput
            aria-label="Fecha de expedición"
            fluid
            label="Fecha de expedición"
            required
            name="date"
            value={date}
            onChange={onChange}
          />
        </Form.Group>
      </Grid.Column>
    </React.Fragment>
  );
};

const TicketForm = () => {
  const [ticket, setTicketInfo] = useState(initialTicketState);
  const [seller, setSellerInfo] = useState(getPartInitialState("seller"));
  const [buyer, setBuyerInfo] = useState(getPartInitialState("buyer"));
  const [items, setItems] = useState([]);
  const [errors, setErrors] = useState([]);
  const [screenWidthSize] = useScreenSize();
  const downloadRef = useRef();

  const changeHandler = ({ target }) => {
    setTicketInfo((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handleTicketGenaration = (e) => {
    validateTicket(ticket, items, buyer, seller)
      .then(({ ticket, items, buyer, seller }) => {
        setErrors([]);
        if (!seller.id) saveActor(seller);
        if (!buyer.id) saveActor(buyer);
        const schema = parseTicket(ticket, items, seller, buyer);
        const xml = generateXml(schema);
        const blob = new Blob([xml]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        downloadRef.current.setAttribute("href", fileDownloadUrl);
        downloadRef.current.click();
      })
      .catch((err) => {
        if (Array.isArray(err.inner)) {
          setErrors(err.inner.map(({ message }) => message));
        } else {
          setErrors([err]);
        }
      });
  };

  const columns = ["lg", "xl", "2xl"].includes(screenWidthSize) ? 2 : 1;

  return (
    <React.Fragment>
      <Container style={{ marginTop: "5rem", marginBottom: "5rem" }}>
        <h1 aria-live="polite">Formulario de facturación electrónica</h1>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Grid>
            <Grid.Row columns={columns}>
              <GeneralForm onChange={changeHandler} {...ticket} />
            </Grid.Row>
            <Grid.Row columns={columns} divided={columns === 2}>
              <Grid.Column>
                <PartForm
                  type="Emisor"
                  part={seller}
                  onChange={setSellerInfo}
                />
              </Grid.Column>
              <Grid.Column>
                <PartForm
                  type="Receptor"
                  part={buyer}
                  onChange={setBuyerInfo}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column aria-live="polite">
                <Items items={items} setItems={setItems} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} aria-live="polite">
              <Grid.Column>
                <Summary ticket={ticket} items={items} />
              </Grid.Column>
            </Grid.Row>
            {errors.length > 0 && (
              <Grid.Row>
                <Grid.Column>
                  <ErrorsMessage setErrors={setErrors} errors={errors} />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row columns={1}>
              <Grid.Column aria-live="polite" textAlign="right">
                <Button
                  aria-label="Generar Factura"
                  onClick={handleTicketGenaration}
                  style={{ backgroundColor: "#f8b42d", color: "white" }}
                  type="submit"
                >
                  Generar Factura
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        <a
          style={{ display: "none" }}
          className="hidden"
          download={ticket.seriesCode + ticket.number + ".xml"}
          href="/file.csv"
          ref={downloadRef}
        >
          download it
        </a>
      </Container>
    </React.Fragment>
  );
};

export default TicketForm;
